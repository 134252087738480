@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

input {
  -webkit-appearance: none;
}

.gform_wrapper ul.gform_fields li.gfield {
  padding-right: 0px!important;
}

.gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium, .gform_wrapper input, .gform_wrapper textarea {
  @include gothambook;
  color: $white;
  letter-spacing: -0.010em!important;
  font-size: (14rem/16)!important;
  border-radius: 0;
  box-shadow: 0 0 0 2px rgba(255,255,255,0.3);
  width: 100%!important;
  padding: 16px!important;
  transition: all 0.15s ease-in-out;
  -webkit-appearance: none;
  outline: none;
  border: none;
  background: transparent;
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $ltgray!important;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: $ltgray!important;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: $ltgray!important;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: $ltgray!important;
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(255,255,255,1);
  }
}

#gform_wrapper_7, #gform_wrapper_6, #gform_wrapper_2, section.aboutLili, section.blogIndividual {
  .top_label input.medium, .top_label select.medium, input, textarea {
    background: $spacegrey;
  }
}

.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input[type=submit], #gform_wrapper_7 .gform_footer input[type=button], #gform_wrapper_6 .gform_footer input[type=button], #gform_wrapper_7 .gform_footer input[type=submit], #gform_wrapper_6 .gform_footer input[type=submit], #gform_wrapper_2 .gform_footer input[type=submit], #gform_wrapper_2 .gform_footer input[type=submit] {
  background: $palegold;
  color: $white;
  text-transform: none;
  width: 100%!important;
  position: relative;
  display: block;
  transition: all 0.15s ease-in-out;
  padding: 10px 15px 10px 15px !important;
  cursor: pointer;
  color: $palegray;
  @include gothambold;
  font-size: (16rem/16)!important;
  box-shadow: none;
  @media (min-width: 768px) {
    &:hover {
      background: $dkgold;
    }
  }
  @media (min-width: 992px) {
    width: 33%!important;
  }
  @media (min-width: 1200px) {
    width: 50%!important;
  }
}

.gform_wrapper  .gform_footer {
  margin-top: 0px!important;
  position: relative;
  .buttonLink {
    position: absolute;
    top: 86px;
    right: 0px;
    width: 100%;
    @media (min-width: 992px) {
      width: 45%;
      top: 16px;
      right: 0px;
    }
  }
}

.gform_wrapper span.gform_description {
  @include gothambook;
  font-size: (16rem/16);
  line-height: (24rem/16);
  letter-spacing: 0.050em;
  margin-bottom: 25px!important;
  color: $textgrey;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    margin-bottom: 35px!important;
  }
}

#gform_wrapper_5 {
  padding: 10px;
  box-sizing: border-box;
  background: $palegray;
  border-radius: 0;
  padding: 20px;
  label {
    @include gothambook;
    color: $palegold;
  }
  .gform_footer.top_label {
    padding-bottom: 0px;
  }
}

.dark {
  .col-md-3 #gform_wrapper_5 {
    padding: 0px;
  }
  #gform_wrapper_5 {
    .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium, .gform_wrapper input, .gform_wrapper textarea {
      background: $palegold;
      color: $palegray;
      @media (min-width: 768px) {
        &:hover {
          background: $dkgold;
          color: $white;
        }
      }
    }
  }
}

section.light {
  .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium, .gform_wrapper input, .gform_wrapper textarea {
    color: $palegray;
  }
}
