@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

.idx-omnibar-form {
  background: transparent;
  border-radius: 0px;
  padding: 0px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  .fa-search {
    padding-right: 5px;
  }
  .awesomplete {
    flex: 3 1 75%;
    padding-right: 0px;
    input {
      width: 100%;
      display: block;
      box-sizing: border-box;
      border-radius: 0px;
      border: none;
      padding: 15px 20px;
      @include gothambook;
      font-size: (14rem/16);
      letter-spacing: 0.025em;
      color: $textgrey;
    }
    ul {
      margin: 0px;
      padding: 0px;
    }
  }
  button {
    flex: 1 1 25%;
    border-radius: 0px;
    border: none;
    background: $palegold;
    @include idlewild;
    color: $palegray;
    font-size: (10rem/16);
    text-transform: uppercase;
    letter-spacing: 0.100em;
    max-height: 51px;
    margin: 0px;
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    @media (min-width: 768px) {
      &:hover {
        background: $dkgold;
      }
    }
  }
  label {
    display: none;
  }
}
