@font-face {
    font-family: 'texgyreadventorregular';
    src: url('../fonts/texgyreadventor-regular-webfont.eot');
    src: url('../fonts/texgyreadventor-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/texgyreadventor-regular-webfont.woff') format('woff'),
         url('../fonts/texgyreadventor-regular-webfont.ttf') format('truetype'),
         url('../fonts/texgyreadventor-regular-webfont.svg#texgyreadventorregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'texgyreadventorbold';
    src: url('../fonts/texgyreadventor-bold-webfont.eot');
    src: url('../fonts/texgyreadventor-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/texgyreadventor-bold-webfont.woff') format('woff'),
         url('../fonts/texgyreadventor-bold-webfont.ttf') format('truetype'),
         url('../fonts/texgyreadventor-bold-webfont.svg#texgyreadventorbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'texgyreadventoritalic';
    src: url('../fonts/texgyreadventor-italic-webfont.eot');
    src: url('../fonts/texgyreadventor-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/texgyreadventor-italic-webfont.woff') format('woff'),
         url('../fonts/texgyreadventor-italic-webfont.ttf') format('truetype'),
         url('../fonts/texgyreadventor-italic-webfont.svg#texgyreadventoritalic') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'texgyreadventorbold_italic';
    src: url('../fonts/texgyreadventor-bolditalic-webfont.eot');
    src: url('../fonts/texgyreadventor-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/texgyreadventor-bolditalic-webfont.woff') format('woff'),
         url('../fonts/texgyreadventor-bolditalic-webfont.ttf') format('truetype'),
         url('../fonts/texgyreadventor-bolditalic-webfont.svg#texgyreadventorbold_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Gotham A", "Gotham B";
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Idlewild A", "Idlewild B";
  font-style: normal;
  font-weight: 600;
}

@mixin gothambook {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

@mixin gothamitalic {
  font-family: "Gotham A", "Gotham B";
  font-style: italic;
  font-weight: 400;
}

@mixin gothambold {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

@mixin idlewild {
  font-family: "Idlewild A", "Idlewild B";
  font-style: normal;
  font-weight: 600;
}
