@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

body {
  @include gothambook;
}

.headingOffset {
  position: absolute;
  transform: translateX(-5000px);
  font-size: 12px;
  display: block;
}

h1 {
  @include gothambook;
  color: $white;
  text-transform: uppercase;
  font-size: (24rem/16);
  letter-spacing: 0.100em;
  @media (min-width: 768px) {
    font-size: (42rem/16);
  }
}

h2, h1.h2-style {
  color: $black;
  font-size: (18rem/16);
  @include gothambook;
  letter-spacing: 0.100em;
  text-align: center;
  margin-bottom: 25px;
  text-transform: uppercase;
  span {
    color: $textgrey;
    font-size: (15rem/16);
    @include gothambook;
    text-transform: none;
    display: inline-block;
    margin-left: 10px;
    a {
      margin-left: 5px;
      color: $dkgold;
      transition: all 0.15s ease-in-out;
      &:hover, &:focus {
        text-decoration: none;
        color: $textgrey;
      }
    }
  }
  @media (min-width: 768px) {
    font-size: (26rem/16);
    margin-bottom: 50px;
  }
}

h2.h1-style {
  @include gothambook;
  color: $palegray;
  text-transform: uppercase;
  font-size: (24rem/16);
  letter-spacing: 0.100em;
  @media (min-width: 768px) {
    font-size: (42rem/16);
  }
}

h3 {
  @include gothambook;
  font-size: (22rem/16);
  line-height: (30rem/16);
  margin-bottom: 25px;
}

h4 {
  @include gothambook;
}

h4.propertyAddress {
  text-transform: uppercase;
  font-size: 0.9375rem;
  color: #2f2f3a;
  letter-spacing: 0.100em;
  margin-bottom: 5px;
  font-family: 'texgyreadventorbold', sans-serif!important;
  font-weight: bold!important;
}

h5 {
  @include idlewild;
  font-size: (14rem/16);
}

p {
  @include gothambook;
  font-size: (16rem/16);
  line-height: (24rem/16);
  letter-spacing: 0.050em;
  margin-bottom: 25px;
  a {
    color: $dkgold;
    @media (min-width: 768px) {
      &:hover {
        color: $dkblue;
      }
    }
  }
  @media (min-width: 768px) {
    margin-bottom: 35px;
  }
}

a {
  transition: all 0.15s ease-in-out;
  &:hover, &:active, &:focus {
    text-decoration: none;
  }
}

strong {
  @include gothambold;
}

.buttonLink {
  display: block;
  position: relative;
  clear: both;
  border: 2px solid $palegold;
  padding: 10px 15px 9px 15px;
  @include gothambold;
  font-size: (14rem/16);
  text-align: left;
  color: $white;
  &:hover {
    color: $white;
  }
  &:after {
    transition: all 0.15s ease-in-out;
    content: "";
    position: absolute;
    top: 50%;
    right: 25px;
    display: block;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background: url(../svg/right-arrow-blue.svg);
    background-size: 15px 15px;
  }
  @media (min-width: 768px) {
    &:hover {
      border-color: $dkgold;
      background: $dkgold;
      color: $white;
      &:after {
        opacity: 1;
        transform: translate(3px, -50%);
      }
    }
  }
}

ul {
  margin: 0 0 25px 0;
  padding-left: 18px;
  li {
    @include gothambook;
    font-size: (14rem / 16);
    color: $textgrey;
    margin-bottom: 13px;
  }
  @media (min-width: 768px) {
    margin: 0 0 35px 0;
  }
}

a[href^="tel"] {
  color: $dkblue;
}
