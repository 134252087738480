$black: #262626;
$white: #ffffff;
$spacegrey: #f5f5f5;
$dkblue: #2b2b36;
$textltblue: #343440;
$textdkblue: #2f2f3a;
$textgrey: #777777;
$bluegrey: #949aa9;
$ltbluegrey: #cacdd4;
$dkbluegrey: #7c808f;
$dkgold: #ce9d72;
$ltgold: #e7ceb9;
$palegray: #333340;
$ltblue: #949aa9;
$dividerltgrey: #dadada;
$arrowgrey: #99999f;
$headerltgrey: #aaaaaa;
$headerbordergrey: #555555;

$ltgray: #b2b2b2;
$palegray: #222326;
$palegraylt: #2e2f33;
$borderpalegray: #3b3b3b;
$dkgray: #222326;
$bordergray: #424347;
$palegold: #bfb6ac;
$mdgold: #bfaf8f;
$tan: #f2ece6;
$paragraphgray: #4c4c4c;
