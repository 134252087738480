@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

.SiteHeader {
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background: $palegray;
  background: rgba(46,47,51,0.95);
  border-bottom: 2px solid rgba(66,67,71,0.95);
  transition: all 0.15s ease-in-out;
  .SiteHeader__inside {
    position: relative;
    z-index: 1000;
    display: block;
    clear: both;
    background: transparent;
    padding-top: 0px;
    transition: all 0.15s ease-in-out;
    @media (max-width: 991px) {
      height: 45px;
      padding-top: 0px;
    }
  }
  .SiteHeader__logoWrap {
    background: transparent;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 0.350em;
    font-size: (18rem/16);
    @include gothambook;
    display: block;
    padding: 15px 45px 15px 45px;
    float: left;
    &__logo {
      width: 150px;
      height: auto;
      display: block;
      clear: both;
    }
  }
  .SiteHeader__phone {
    background: $dkgold;
    color: $white;
    @include gothambook;
    font-size: (14rem/16);
    letter-spacing: -0.010em;
    width: 165px;
    float: left;
    padding: 16px 12px 15px 12px;
    white-space: nowrap;
    img {
      max-width: 15px;
      margin-right: 7px;
    }
    @media (min-width: 1120px) {
      padding: 26px 12px;
    }
  }
  .SiteHeader__primaryNavigation {
    display: block;
    margin: 0 auto;
    clear: both;
    letter-spacing: -0.010em;
    font-size: (12rem/16);
    text-transform: uppercase;
    ul {
      display: block;
      list-style: none;
      padding: 0px;
      margin: 0 auto;
      li {
        display: inline-block;
        clear: both;
        text-align: center;
        width: 100%;
        margin: 0;
        a {
          padding: 28px 20px;
          display: block;
          cursor: pointer;
          color: $white;
          img {
            width: 18px;
          }
        }
        ul {
          display: none;
        }
        &:hover {
          ul {
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  @media (min-width: 1120px) {
    max-height: 74px;
    .SiteHeader__logoWrap {
      &__logo {
        width: 201px;
      }
    }
    .SiteHeader__primaryNavigation {
      width: calc(100% - 291px);
      max-width: 100%;
      clear: none;
      float: left;
      > div > ul {
        max-height: 74px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        > li {
          display: inline-block;
          clear: none;
          width: auto;
          overflow: hidden;
          a {
            transition: color 0.15s ease-in-out, background 0.15s ease-in-out, border 0.15s ease-in-out;
            padding-left: 20px;
            padding-right: 20px;
            font-size: (12rem/16);
            white-space: nowrap;
            line-height: 22px;
            @media (min-width: 768px){
              &:hover {
                text-decoration: none;
                color: $palegold!important;
              }
            }
          }
          &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
            @include gothambook;
            letter-spacing: -0.010em;
            align-self: left;
            a {
              color: $white;
              font-size: (12rem / 16);
              line-height: 18px;
              padding-top: 28px;
              padding-bottom: 29px;
              padding-left: 15px;
              padding-right: 15px;
            }
          }
          &:nth-child(6) {
            a {
              padding-right: 30px;
            }
          }
          &:nth-child(8) {
            a {
              position: relative;
              padding-left: 35px;
              &:before {
                content: url(../svg/phone-icon.svg);
                position: absolute;
                left: 14px;
                top: 50%;
                transform: translateY(-50%);
                width: 15px;
                height: 15px;
                display: block;
              }
            }
          }
          &:nth-child(7) {
            a {
              background: $palegray;
              border-left: 2px solid $bordergray;
              border-right: 2px solid $bordergray;
            }
          }
          ul.sub-menu {
            position: absolute;
            top: calc(100% + 2px);
            left: 0;
            display: block;
            z-index: -1;
            visibility: hidden;
            opacity: 0;
            transition: all 0.18s ease-in-out;
            width: 100%;
            background: $palegray;
            padding: 10px;
            max-height: 1000px;
            &:after {
              content: "";
              display: table;
              clear: both;
            }
            > li {
              width: 25%;
              display: inline-block;
              float: left;
              clear: none;
              a {
                background: $palegray;
                color: $ltgray;
                padding: 10px;
                width: 100%;
                text-align: left;
                &:after {
                  display: none;
                }
                &:hover {
                  color: $white!important;
                }
              }
            }
          }
          a:hover + ul.sub-menu, ul.sub-menu:hover {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  @media (min-width: 1120px) {
    .SiteHeader__primaryNavigation {
      > div > ul {
        > li {
          &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
            a {
              font-size: (11rem / 16);
              padding: 27px 10px;
            }
          }
          &:nth-child(7) a {
            padding: 27px 10px;
          }
          &:nth-child(8) a {
            padding: 27px 10px 27px 35px;
          }
        }
      }
    }
  }
  @media (min-width: 1250px) {
    .SiteHeader__primaryNavigation {
      > div > ul {
        > li {
          &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7), &:nth-child(8) {
            a {
              font-size: (13rem / 16);
              padding: 27px 15px;
            }
          }
          &:nth-child(6) {
            a {
              padding-right: 40px;
            }
          }
          &:nth-child(8) {
            a {
              background: $palegray;
              padding-left: 43px;
              &:before {
                left: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 1119px) {
    .SiteHeader__primaryNavigation {
      position: absolute;
      top: 59px;
      padding-bottom: 0px;
      max-height: 0px;
      overflow: hidden;
      transition: all 0.15s ease-out;
      width: 100%;
      background: $palegray;
      ul {
        display: flex;
        flex-wrap: wrap;
        font-size: (14rem / 16);
        li {
          flex-grow: 1;
          width: 25%;
          a {
            color: $white;
            text-decoration: none;
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
      }
      &.active {
        max-height: 400px;
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
    .SiteHeader__logoWrap {
      opacity: 1;
      left: 15px;
      top: 15px;
      z-index: 10;
      padding: 13px 10px;
      img {
        width: 60px;
      }
    }
    .SiteHeader__phone {
      font-size: (12rem / 16);
      padding: 18px 10px 17px 10px;
      width: 140px;
      background: transparent;
      color: $textdkblue;
    }
  }
  @media (max-width: 700px) {
    .SiteHeader__primaryNavigation {
      ul {
        li {
          width: 50%;
        }
      }
    }
    .SiteHeader__phone {
      padding: 18px 5px 17px 5px;
    }
  }
  @media (min-width: 1119px) {
    &.small {
      .SiteHeader__logoWrap {
        opacity: 1;
        visibility: visible;
        top: 5px;
      }
      .SiteHeader__inside {
        // padding-top: 10px;
        // padding-bottom: 10px;
        ul{
          li {
            a {
              &:hover {
              }
            }
            &:after {
            }
          }
        }
      }
    }
  }
  &__frontPage {
    transition: background 0.15s ease-in-out, border-color 0.15s ease-in-out;
    background: transparent;
    border-bottom: 2px solid rgba(66,67,71,0);
    .SiteHeader__primaryNavigation>div>ul>li:nth-child(7) a {
      border-color: transparent;
      background: transparent;
    }
    .SiteHeader__primaryNavigation>div>ul>li:nth-child(8) a {
      background: transparent;
      border-color: transparent;
    }
    &.scrolled {
      border-top: 0px;
      background: $palegray;
      background: rgba(46,47,51,0.95);
      border-bottom: 2px solid rgba(66,67,71,0.95);
      .SiteHeader__primaryNavigation>div>ul>li:nth-child(7) a {
        border-color: $bordergray;
        background: $palegray;

      }
      .SiteHeader__primaryNavigation>div>ul>li:nth-child(8) a {
        background: $palegray;
      }
    }
  }
}

.navExpander {
  position: absolute;
  top: 17px;
  right: 15px;
  z-index: 1000;
  width: 20px;
  height: 16px;
  cursor: pointer;
  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background: $ltgray;
    left: 0px;
    transition: all 0.15s ease-in-out;
    opacity: 1;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 50%;
    }
    &:nth-child(4) {
      top: 100%;
    }
  }
  &.active {
    span:nth-child(1), span:nth-child(4) {
      transform: scaleX(0);
      opacity: 0;
    }
    span:nth-child(2) {
      transform: rotate(45deg);
    }
    span:nth-child(3) {
      transform: rotate(-45deg);
    }
  }
  @media (min-width: 1120px) {
    display: none;
  }
}
