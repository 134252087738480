@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

footer {
  padding: 0 15px;
  display: block;
  clear: both;
  p {
    font-size: (12rem / 16);
    color: $ltbluegrey;
    line-height: (16rem / 16);
    a {
      color: $white;
      &:hover {
        color: $textdkblue;
        text-decoration: none;
      }
    }
  }
}

.SiteFooter {
  &__footerWrap {
    width: 100%;
    padding: 28px 0;
    display: block;
    clear: both;
    position: relative;
    z-index: 500;
    background: $palegraylt;
    color: $ltgray;
    border-top: 2px solid $bordergray;
    @media (min-width: 576px) {
      padding: 75px 0;
    }
  }
  &__logoWrap {
    max-width: 205px;
    display: block;
    position: relative;
    clear: both;
    svg {
      width: 100%;
      height: auto;
      display: block;
      clear: both;
    }
  }
  &__footerSocial {
    display: block;
    clear: both;
    width: 100%;
    padding-bottom: 23px;
    margin-bottom: 23px;
    position: relative;
    display: flex;
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 30px;
      height: 2px;
      background: $ltbluegrey;
      display: block;
    }
    a {
      flex: 1;
      float: left;
      width: calc(25% -5px);
      margin-right: 5px;
      max-width: 32px;
      position: relative;
      border-radius: 16px;
      border: 2px solid $ltbluegrey;
      padding: 6px;
      img {
        display: block;
        width: 100%;
      }
    }
  }
  &__footerContact {
    color: $ltgray;
    transition: color 0.15s ease-in-out;
    @include gothambook;
    letter-spacing: 0.050em;
    font-size: (15rem / 16);
    width: 100%;
    display: block;
    clear: both;
    margin-bottom: 6px;
    img {
      max-width: 25px;
      margin-right: 5px;
    }
    a {
      color: $ltgray;
      width: 100%;
      clear: both;
      letter-spacing: 0.150em;
      line-height: 25px;
      @media (min-width: 768px) {
        &:hover {
          color: $ltgold;
        }
      }
    }
  }
  &__socialContactWrap {
    margin-top: 10px;
    @media (min-width: 576px) and (max-width: 767px) {
      margin-top: -141px;
    }
    @media (min-width: 992px) {
      margin-top: 0px;
    }
  }
}

#menu-footer-menu-1, #menu-footer-menu-2 {
  margin: 0px;
  padding: 0px;
  list-style: none;
  li {
    margin-bottom: 0px;
    a {
      color: $ltgray;
      transition: color 0.15s ease-in-out;
      @include gothambook;
      letter-spacing: 0.050em;
      font-size: (15rem / 16);
      padding: 8px 0 7px 0;
      display: block;
      clear: both;
      @media (min-width: 768px) {
        &:hover {
          color: $ltgold;
        }
      }
    }
    &:first-child a {
      padding-top: 0px;
    }
  }
}

#menu-footer-menu-1 {
  margin-top: 20px;
  @media (min-width: 576px) {
    margin-top: 0px;
  }
}
