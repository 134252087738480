@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

body.page-template-search-results-wrapper {
  background: $spacegrey;
  main {
    background: transparent;
  }
}

#IDX-main {
  @include gothambook;
  padding-bottom: 50px;
  color: $black;
  h3 {
    color: $palegray;
    font-size: (24rem / 16);
    @include gothambook;
    &.IDX-fieldContainerTitle {
      margin-bottom: 0px;
      margin-top: 10px;
    }
  }
  p, span, strong {
    font-size: (16rem / 16);
    line-height: (24rem / 16);
  }
  strong {
    @include gothambold;
  }
  a {
    color: $dkblue;
    &:hover {
      color: $textgrey;
    }
  }
  .IDX-resultsLabel {
    color: $dkblue;
  }
  .IDX-fieldData {
    color: $palegray;
  }
  #IDX-scheduleShowing, #IDX-main .IDX-detailsVirtualTourLink {
    color: $white;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    transition: all 0.15s ease-in-out;
    letter-spacing: 0.150em;
    padding: 15px 70px 15px 25px;
    @include gothambold;
    font-size: (13rem / 16);
    border-radius: 0px;
    margin-bottom: 25px;
    background: $dkblue;
    text-shadow: none;
    box-shadow: none;
    @media (min-width: 768px) {
      &:hover {
        background: $dkgold;
      }
    }
    @media (min-width: 900px) {
      width: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 25px;
      display: block;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background: url(../svg/right-arrow-blue.svg);
      background-size: 10px 10px;
    }
  }
  .IDX-fieldLabel, .IDX-detailsLabel {
    color: $ltgray;
  }
  #IDX-fieldsWrapper {
    strong {
      color: $dkblue;
    }
  }
  .IDX-column2 p, span {
    font-size: (13rem / 16);
  }
  .IDX-column2 {
    span.IDX-resultsLabel {
      @include gothambook;
      color: $paragraphgray;
    }
    span.IDX-resultsText {
      color: $palegray;
      @include gothambold;
    }
    .IDX-resultsField-price .IDX-resultsText {
      color: $palegray;
      font-size: (16rem / 16);
      @include gothambold;
    }
  }
  .IDX-resultsDescription {
    font-size: (16rem / 16);
    color: $paragraphgray;
    line-height: auto!important;
  }
  .IDX-resultsField-listingPrice, .IDX-resultsField-price {
    font-size: (16rem / 16);
    span {
      font-size: (16rem / 16);
    }
  }
  .IDX-resultsAddress {
    margin-bottom: 15px;
  }
  .IDX-resultsAddress span {
    color: $palegray;
    font-size: (22rem / 16);
    @include gothambook;
    margin-bottom: 15px;
  }
  .IDX-resultsCell {
    padding: 30px 0;
    margin-bottom: 0px;
  }
  input:not([type=radio]), select, textarea {
    @include gothambook;
    letter-spacing: 0.050em!important;
    color: $palegray;
    font-size: (13rem/16)!important;
    border: 0px solid transparent;
    width: 100%!important;
    padding: 10px!important;
    background: $spacegrey;
    border-radius: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    transition: all 0.15s ease-in-out;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $ltgray!important;
      text-transform: uppercase!important;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $ltgray!important;
      text-transform: uppercase!important;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $ltgray!important;
      text-transform: uppercase!important;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $ltgray!important;
      text-transform: uppercase!important;
    }
    &:hover, &:active, &:focus {

    }
  }
  select {
    background-color: $spacegrey;
    background-image: url(../images/select-arrow.png);
    background-position: calc(100% - 15px) 13px;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    &:hover, &:active, &:focus {

    }
  }

  button, input[type=submit], .IDX-btn {
    background: $palegray;
    color: $white;
    border: 2px solid $palegold;
    text-transform: uppercase;
    width: 100%!important;
    position: relative;
    display: block;
    transition: all 0.15s ease-in-out;
    text-shadow: none;
    box-shadow: none;
    border: none;
    border-radius: 0px;
    padding: 15px;
    @include gothambold;
    @media (min-width: 768px) {
      &:hover {
        background: $dkgold;
        border-color: $dkgold;
      }
    }
    @media (min-width: 992px) {
      width: 33%!important;
    }
    @media (min-width: 1200px) {
      width: 50%!important;
    }
  }
  ul.select2-choices {
    border: 0px;
    background: $spacegrey;
    background-image: none;
    border-radius: 4px;
  }
  .IDX-control-group > label {
    @include gothambook;
    color: $palegray;
    font-size: (16rem / 16);
  }
  #IDX-propSubType-group {
    display: none;
  }
  #IDX-fieldsWrapper {
    span {
      font-size: (16rem / 16);
    }
  }
  .IDX-button, .IDX-detailsVirtualTourLink {
    border: none;
  }
  #IDX-resetBtn {
    display: none;
  }
  #IDX-submitBtn {
    margin-bottom: 35px;
    width: 45%!important;
  }
  .IDX-fieldContainerList {
    padding-left: 0px;
    padding-right: 0px;
  }
  #IDX-detailsAddress {
    span {
      @include gothambook;
      font-size: (22rem / 16);
      color: $palegray;
    }
  }
  #IDX-detailsField-listingPrice {
    .IDX-detailsText {
      color: $palegray;
      @include gothambold;
      font-size: (16rem / 16);
    }
  }
  #IDX-detailsHead {
    #IDX-detailsAddress, #IDX-detailsField-listingPrice, #IDX-detailsField-propStatus {
      width: 68%;
      text-align: left;
      margin-top: 15px;
      span {
        text-align: left!important;
      }
    }
    #IDX-detailsField-bedrooms, #IDX-detailsField-fullBaths, #IDX-detailsField-totalBaths, #IDX-detailsField-partialBaths, #IDX-detailsField-sqFt, #IDX-detailsField-acres, #IDX-detailsField-listingID {
      text-align: left;
      span {
        text-align: left;
      }
    }
  }
}

.IDX-main select {
  padding: 15px!important;
}

// Property Showcase
.IDX-showcaseContainer {
  border: 1px solid $dkblue!important;
  box-shadow: none!important;
  font-size: (14rem / 16);
  > div {
    color: $dkblue;
    @include gothambook;
  }
}

.IDX-showcaseAddress {
  text-transform: uppercase;
  font-size: 0.9375rem;
  color: #2f2f3a;
  letter-spacing: 0.100em;
  margin-bottom: 5px;
  font-family: 'texgyreadventorbold', sans-serif!important;
  font-weight: bold!important;
}

.IDX-showcaseCityStateZip {
  margin-bottom: 0px;
  span {
    color: #777777;
    font-size: 0.875rem;
  }
}

.IDX-showcasePrice {
  font-family: 'texgyreadventorbold', sans-serif;
  font-weight: bold;
  font-size: 0.8125rem;
  color: #ffffff!important;
  background: #333340;
  padding: 12px 20px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  letter-spacing: 0.150em;
}

.IDX-showcaseContainer {
  margin-bottom: 25px;
  @media (max-width: 480px) {
    padding-bottom: 30px!important;
  }
}

#IDX-ccz-group .IDX-active {
  @include gothambold;
}

select#IDX-per {
  max-width: 75%!important;
}

#IDX-formReset {
  max-width: 25%!important;
}

#IDX-search-row-content #IDX-coreFieldRow, #IDX-cczContainer, .IDX-coreFields {
  @media (max-width: 767px) {
    width: 100%;
    display: block;
    clear: both;
  }
}

#IDX-submit-group {
  width: 100%;
  display: block;
  clear: both;
  margin: 20px 0 15px 0;
}

#IDX-formReset {
  background: transparent!important;
  color: $textgrey !important;
  padding: 0px!important;
  text-align: left!important;
}

#IDX-results-row-content {
  @media (max-width: 991px) {
    .IDX-column1, .IDX-column2, .IDX-column3, .IDX-column4 {
      width: 50%;
    }
    .IDX-column2 {
      width: calc(50% - 4%);
      margin-left: 4%;
    }
    .IDX-column3 {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 0px;
      padding: 0;
      .IDX-resultsDescription {
        line-height: 1.4em;
      }
    }
    .IDX-column3, .IDX-column4 {
      width: 100%;
    }
    .IDX-column4 {
      > div, > span, > a {
        width: 33.33333333%;
        display: block;
        float: left;
        padding: 5px;
        box-sizing: border-box;
        text-align: left;
      }
      .IDX-detailsMLSLogo img {
        display: none;
      }
    }
  }
}

#IDX-detailsField-propStatus {
  width: 50%!important;
  clear: left;
}
#IDX-detailsBankRateTool {
  width: 50%!important;
  margin-top: 15px;
}

@media (max-width: 991px) {
  #IDX-detailsHeadFields {
    clear: left;
    margin-top: 15px;
    > div {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  #IDX-detailsField-listingID {
    clear: left;
    margin-top: 15px;
  }
  #IDX-detailsFeatured {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #IDX-detailsFeatured #IDX-detailsField-propStatus {
    width: 100%!important;
  }
}


#IDX-description {
  color: $paragraphgray!important;
  font-size: 1rem;
  line-height: 1.4rem;
  @media (max-width: 991px) {
    width: 100%;
    > div {
      margin-bottom: 15px;
    }
  }
}

@media (max-width: 991px) {
  #IDX-photoListing {
    width: 50%!important;
  }
  #IDX-detailsAddress, #IDX-detailsField-listingPrice, #IDX-detailsField-propStatus, #IDX-detailsBankRateTool {
    width: 48%!important;
    margin: 0;
    clear: none;
  }
  #IDX-detailsBankRateTool {
    margin-top: 15px;
    text-align: left;
    a {
      clear: both;
      display: block;
    }
  }
}

@media (min-width: 992px) {
  #IDX-detailsField-listingPrice {
    width: 24%!important;
    margin: 0;
  }
  #IDX-main #IDX-detailsHead #IDX-detailsField-propStatus, #IDX-main #IDX-detailsHead #IDX-detailsBankRateTool {
    width: 24%!important;
    display: block;
    float: left;
    clear: none;
  }
  #IDX-detailsHeadFields {
    width: 68%!important;
    float: left;
    display: block;
  }
  #IDX-main #IDX-detailsHead #IDX-detailsField-propStatus,
  #IDX-main #IDX-detailsHead #IDX-detailsField-bedrooms, #IDX-main #IDX-detailsHead #IDX-detailsField-fullBaths, #IDX-main #IDX-detailsHead #IDX-detailsField-totalBaths, #IDX-main #IDX-detailsHead #IDX-detailsField-partialBaths, #IDX-main #IDX-detailsHead #IDX-detailsField-sqFt, #IDX-main #IDX-detailsHead #IDX-detailsField-acres, #IDX-main #IDX-detailsHead #IDX-detailsField-listingID,
  #IDX-detailsField-listingPrice {
    width: 15%!important;
    margin-right: 1.66666666667%!important;
  }
  #IDX-detailsBankRateTool {
    text-align: left;
  }
  #IDX-detailsBankRateTool a {
    clear: both;
    display: block;
    float: left;
    text-align: left;
  }
}

@media (max-width: 500px) {
  #IDX-photoListing {
    width: 100%!important;
    margin-right: 0px!important;
  }
  #IDX-detailsAddress {
    width: 100%!important;
  }
  #IDX-scheduleShowing, #IDX-main .IDX-detailsVirtualTourLink {
    width: 100%!important;
  }
}

#IDX-detailsLinkModify {
  position: absolute;
  top: 0px;
  right: 210px;
  white-space: nowrap;
}

#IDX-contentFields-core #IDX-detailsField-propStatus {
  width: 100%!important;
}

h4#IDX-resultsSeparatorTextFeatured {
  font-size: (16rem / 16);
}

h4#IDX-resultsSeparatorTextActive {
  margin-top: 30px;
  font-size: (16rem / 16);
}

#IDX-main .IDX-resultsPerPageSelector {
  padding: 5px 10px!important;
  background-position: calc(100% - 15px) 9px;
}

#IDX-openHouses {
  background: $spacegrey;
}

#IDX-contentFields .IDX-detailsField {
  background: white;
}
