@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

.aboutLiliIntro {
  margin-top: 53px;
  padding: 50px 0 100px 0;
  background: $palegray;
  background-size: cover;
  position: relative;
  h1, h2 {
    text-align: left;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    color: $palegold;
    text-transform: none;
    @include gothambook;
    letter-spacing: 0em;
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    margin-top: 74px;
    padding: 100px 0 200px 0;
    h2 {
      font-size: (22rem/16);
      line-height: (30rem/16);
      margin-bottom: 45px;
    }
  }
}

.page-template-about-lili-banani main {
  position: relative;
  z-index: 100;
  background: transparent;
  padding-bottom: 0px;
}

.aboutLili {
  background: $white;
  z-index: 100;
  position: relative;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  padding-top: 65px;
  padding-bottom: 75px;
  a {
    color: $paragraphgray;
  }
  @media (min-width: 450px) {
    width: calc(100% - 50px);
    margin: 0 auto 0 auto;
    margin-top: 0px;
  }
  @media (min-width: 768px) {
    padding-top: 100px;
    margin-top: 0px;
    padding-bottom: 150px;
    h2 {
      margin-bottom: 20px;
    }
  }
  @media (min-width: 1200px) {
    width: 100%;
    margin-top: 0px;
  }
  @media (min-width: 1300px) {
    // width: 1275px;
  }
  @media (min-width: 1400px) {
    // width: 1310px;
  }
}

.aboutLili__testimonialSlider {
  margin-top: 75px;
  padding: 75px 0;
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  p {
    @include gothambook;
    color: $black;
    font-size: (22rem / 16);
    line-height: (30rem/16);
    &.author {
      @include gothambook;
      color: $palegold;
      font-size: (14rem / 16);
      line-height: (18rem / 16);
      letter-spacing: 0.025em;
    }
  }
  @media (min-width: 768px) {
    margin-top: 150px;
  }
}
