@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

body.page-template-blog-overview, body.single, body.page-template-default {
  background: $spacegrey;
  main {
    position: relative;
    z-index: 100;
    background: transparent;
    padding-bottom: 0px;
  }
}

body.archive main {
  position: relative;
  z-index: 100;
  background: transparent;
  padding-bottom: 0px;
}

.blogOverviewIntro, .blogIndividualIntro {
  margin-top: 59px;
  padding: 50px 0 50px 0;
  background: $palegray;
  background-size: cover;
  position: relative;
  h1, h2 {
    text-align: left;
  }
  h1, h2.h1-style {
    margin: 0px;
    color: $white!important;
  }
  h2:not(.h1-style) {
    color: $ltgray;
    text-transform: none;
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    padding: 100px 0 100px 0;
    h2:not(.h1-style) {
      font-size: (22rem/16);
      line-height: (30rem/16);
      margin-bottom: 45px;
    }
  }
  @media (min-width: 1120px) {
    margin-top: 74px;
  }
}

section.blogOverview.dark h2, section.blogIndividual.dark h2 {
  @include gothambook;font-size: (22rem/16);
}

.blogOverview, .blogIndividual {
  background: $white;
  z-index: 100;
  position: relative;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  padding-top: 65px;
  padding-bottom: 65px;
  h2, h1.h2-style {
    text-align: left;
    margin-bottom: 15px;
    color: $white;
    @include gothambook;
    a {
      color: $white;
      @media (min-width: 768px) {
        &:hover {
          color: $palegold;
        }
      }
    }
  }
  p {
    color: $textgrey;
  }
  .row .row {
    border-bottom: 1px solid #cccccc;
    margin-bottom: 35px;
    @media (min-width: 768px) {
      padding-bottom: 60px;
      margin-bottom: 55px;
    }
    p {
      a {
        color: $white;
        text-transform: uppercase;
        @include gothambold;
        font-size: (14rem / 16);
        @media (min-width: 768px) {
          &:hover {
            color: $palegold;
          }
        }
      }
    }
  }
  span.dateTags {
    width: 100%;
    display: block;
    clear: both;
    margin-bottom: 15px;
    a {
      color: $textgrey;
      @media (min-width: 768px) {
        &:hover {
          color: $palegold;
        }
      }
    }
    span.date {
      color: $white;
      @include gothambook;
      font-size: (14rem / 16);
      display: inline-block;
    }
    span.tags {
      display: inline-block;
      font-size: (16rem / 16);
      letter-spacing: 0.025em;
      span {
        color: $textgrey;
        display: inline-block;
        padding: 0 10px;
        transform: translateY(-2px);
      }
      ul {
        display: inline-block;
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          display: inline;
          padding: 0;
          margin: 0;
          font-size: (14rem / 16);
        }
      }
    }
  }
  @media (min-width: 450px) {
    width: 100%;
    margin: 0 auto 0 auto;
    margin-top: 0;
  }
  @media (min-width: 768px) {
    padding-top: 100px;
    margin-top: 0;
  }
  @media (min-width: 1200px) {
    width: 100%;
    margin-top: 0;
  }
  @media (min-width: 1300px) {
    width: 100%;
  }
  @media (min-width: 1400px) {
    width: 100%;
  }
}

.blogIndividual {
  span.dateTags {
    span.date {
      color: $palegray;
    }
  }
}

.blog__sideBar {
  .buttonLink {
    width: 100%;
    &:first-of-type {
      margin-bottom: 5px;
    }
    &:nth-of-type(2) {
      margin-bottom: 50px;
    }
  }
}

.blogIndividual p {
  color: $paragraphgray;
}

.blogIndividual p:first-of-type strong {
  color: $palegray;
  @include gothambook;
  font-weight: normal;
  font-size: 1.375rem;
  line-height: 1.875rem;
}

.blogIndividualIntro {
  background: $palegray;
  background-size: cover;
}
