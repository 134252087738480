@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

.neighborhoodsIntro {
  margin-top: 59px;
  padding: 50px 0 100px 0;
  background: $palegray;
  background-size: cover;
  position: relative;
  h1, h2 {
    text-align: left;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    color: $ltgray;
    text-transform: none;
    @include gothambook;
    letter-spacing: 0em;
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    padding: 100px 0 200px 0;
    h2 {
      font-size: (22rem/16);
      line-height: (30rem/16);
      margin-bottom: 45px;
    }
  }
  @media (min-width: 1120px) {
    margin-top: 74px;
  }
}

.page-template-neighborhoods main, .page-template-single-neighborhoods main {
  position: relative;
  z-index: 100;
  background: transparent;
  padding-bottom: 0px;
}

.neighborhoods {
  background-color: $white;
  background-image: url(../images/neighborhoods-bg.png);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 0px 0px;
  z-index: 100;
  position: relative;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  padding-top: 65px;
  padding-bottom: 75px;
  @media (min-width: 768px) {
    background-size: 335px 419px;
    background-position: right 116px;
  }
  @media (min-width: 992px) {
    background-size: 440px 550px;
    background-position: right 116px;
  }
  @media (min-width: 1185px) {
    background-size: 530px 663px;
    background-position: right top;
  }
  @media (min-width: 1325px) {
    background-size: 610px 763px;
    background-position: right top;
  }
  .neighborhoodList {
    margin-top: 75px;
    @media (min-width: 768px) {
      margin-top: 140px;
    }
  }
  @media (min-width: 450px) {
    width: calc(100% - 50px);
    margin: 0 auto 0 auto;
    margin-top: -65px;
  }
  @media (min-width: 768px) {
    padding-top: 100px;
    margin-top: -100px;
    padding-bottom: 150px;
    h2 {
      margin-bottom: 20px;
    }
  }
  @media (min-width: 1200px) {
    width: 1175px;
    margin-top: -100px;
  }
  @media (min-width: 1300px) {
    width: 1275px;
  }
  @media (min-width: 1400px) {
    width: 1310px;
  }
}

.neighborhoodTile {
  margin-bottom: 45px;
  position: relative;
  height: 100px;
  overflow: hidden;
  h4 {
    text-transform: uppercase;
    font-size: (15rem / 16);
    color: $white;
    letter-spacing: 0.100em;
    text-align: left;
    padding-bottom: 45px;
    transition: all 0.15s ease-in-out;
    z-index: 2;
    position: absolute;
    left: 25px;
    bottom: 15px;
    margin: 0;
    padding: 0;
  }
  a {
    display: block;
    clear: both;
    background: $white;
    transition: all 0.15s ease-in-out;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    @media (min-width: 768px) {
      &:hover {
        h4 {
          color: $dkgold;
        }
      }
    }
  }
  .gradientOverlay {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    background: linear-gradient(45deg, rgba(34,35,38,1) 10%, rgba(9,9,121,0) 100%);
    z-index: 1;
  }
  img {
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 7px;
    width: calc(100% - 14px);
    height: 1px;
    background: #e5e5e5;
  }
}
