@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

body.page-template-contact {
  background: $spacegrey;
  main {
    position: relative;
    z-index: 100;
    background: transparent;
    padding-bottom: 0px;
  }
}

.contactIntro {
  margin-top: 53px;
  padding: 50px 0 100px 0;
  background: $palegray;
  background-size: cover;
  position: relative;
  h1, h2 {
    text-align: left;
  }
  h1, h2.h1-style {
    margin: 0px;
  }
  @media (min-width: 768px) {
    margin-top: 74px;
    padding: 100px 0 200px 0;
  }
}
