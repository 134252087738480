@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

.textCentered {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textLeftLarge {
  @media (min-width: 768px) {
    text-align: left;
  }
}

.linkRight {
  a {
    display: inline-block;
    text-align: right;
    float: right;
    clear: none;
    // display: block;
    // clear: both;
    font-size: (14rem/16);
    text-transform: none;
    letter-spacing: -0.010em;
    color: $palegray;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px dotted $palegray;
      bottom: -1px;
      left: 0;
      visibility: hidden;
      -webkit-transform: scaleX(0);
      transform: translateY(2px);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      opacity: 0;
    }
    @media (max-width: 767px) {
      position: absolute;
      top: 2px;
      right: 7px;
    }
    @media (min-width: 768px) {
      display: inline-block;
      text-align: right;
      float: right;
      clear: none;
      &:hover {
        &:after {
          visibility: visible;
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
  &__dark {
    a {
      color: $white;
      &:after {
        border-bottom: 2px dotted $white;
      }
    }
  }
}

.white {
  color: $white;
}

.gold {
  color: $dkgold;
}

.top50 {
  padding-top: 50px;
}

.top0 {
  margin-top: 0px!important;
}

.bottom10 {
  margin-bottom: 10px;
}

.padLeft15 {
  padding-left: 15px;
}

.bottom0 {
  margin-bottom: 0px;
}

.lastMobile {
  @media (max-width: 767px) {
    order: 2;
  }
}

.justify-content-center {
  -webkit-box-pack: center!important;
  -webkit-justify-content: center!important;
  -ms-flex-pack: center!important;
  justify-content: center!important;
}

.borderTop {
  padding-top: 25px;
  border-top: 1px solid #ccc;
  margin-top: 80px;
}

.max100 {
  max-width: 100%;
}

.max100Overflow {
  max-width: calc(100% + 14px);
  transform: translateX(-7px);
}

.bottom25 {
  margin-bottom: 25px;
}

.noUppercase {
  text-transform: none;
}

.page-template-boston-market-report main {
  padding-bottom: 0px;
}

.buildingIntroDetailsWrap {
  @media (min-width: 1200px) {
    margin-top: -90px;
  }
}
span.buildingIntroDetails {
  text-transform: uppercase;
  @include gothambook;
  color: $white;
  font-size: (13rem / 16);
  text-transform: uppercase;
  letter-spacing: 0.150em;
  text-align: left;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 75px;
  > span {
    padding: 15px 15px 15px 15px;
    font-size: (20rem/16);
    &:first-child {
      padding-left: 0px;
    }
    &:last-child {
      padding-right: 0px;
    }
    > span {
      display: block;
      clear: both;
      color: $ltgray;
      text-transform: none;
      font-size: (14rem/16);
      padding: 0;
    }
  }
  @media (max-width: 767px) {
    margin-bottom: 25px;
  }
}
.buildingIntroLinks {
  @media (min-width: 768px) {
    a.buttonLink {
      float: right;
      margin-left: 5px;
    }
  }
}

br.only-mobile {
  @media (min-width: 768px) {
    display: none;
  }
}
