@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

.soldShowcaseIntro {
  margin-top: 59px;
  padding: 50px 0 100px 0;
  background: $palegray;
  background-size: cover;
  position: relative;
  h1, h2 {
    text-align: left;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    color: $dkgold;
    text-transform: none;
    @include gothambook;
    letter-spacing: 0em;
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    padding: 100px 0 200px 0;
    h2 {
      font-size: (22rem/16);
      line-height: (30rem/16);
      margin-bottom: 25px;
    }
  }
  @media (min-width: 1120px) {
    margin-top: 74px;
  }
}

.page-template-sold-showcase main {
  position: relative;
  z-index: 100;
  background: transparent;
}

.soldShowcase {
  background: $white;
  z-index: 100;
  position: relative;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  padding-top: 65px;
  @media (min-width: 450px) {
    width: calc(100% - 50px);
    margin: 0 auto 0 auto;
    margin-top: -65px;
  }
  @media (min-width: 768px) {
    padding-top: 100px;
    margin-top: -100px;
  }
  @media (min-width: 1200px) {
    width: 1175px;
    margin-top: -100px;
  }
  @media (min-width: 1300px) {
    width: 1275px;
  }
  @media (min-width: 1400px) {
    width: 1310px;
  }
}

.soldShowcaseBlocks {
  > .col-md-6 {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 25px;
    padding-bottom: 25px;
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 7px;
      width: calc(100% - 14px);
      height: 1px;
      background: #e5e5e5;
    }
    img {
      width: 100%;
    }
    .image-price-wrap {
      position: relative;
      margin-bottom: 50px;
      .price {
        @include gothambold;
        font-size: (13rem / 16);
        color: $white;
        background: $dkgold;
        padding: 12px 20px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%,50%);
        letter-spacing: 0.150em;
      }
      .img-wrap {
        width: 100%;
        height: 225px;
        position: relative;
        display: block;
        clear: both;
        overflow: hidden;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          display: block;
        }
      }
    }
    h4, p {
      text-align: center;
    }
    h4 {
      text-transform: uppercase;
      font-size: (15rem / 16);
      color: $textdkblue;
      letter-spacing: 0.100em;
      margin-bottom: 5px;
    }
    p {
      color: $textgrey;
      font-size: (14rem / 16);
      letter-spacing: 0.025em;
    }
    .propertyDetails {
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      font-size: (12rem / 16);
      letter-spacing: 0.025em;
      color: $textgrey;
      span {
        flex: 1;
        position: relative;
        &:after {
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 300%;
          content: "";
          background: #e5e5e5;
        }
        &:last-child:after {
          display: none;
        }
        a {
          @include gothambold;
          color: $textdkblue;
          text-transform: uppercase;
          display: block;
          position: relative;
          &:after {
            content: "";
            background: url(../svg/right-arrow-gold.svg);
            position: absolute;
            top: 50%;
            right: calc(50% - 5px);
            transform: translate(30px, -50%);
            width: 10px;
            height: 10px;
            display: block;
            transition: all 0.15s ease-in-out;
          }
          &:hover {
            color: $dkgold;
            text-decoration: none;
            &:after {
              transform: translate(33px, -50%);
            }
          }
        }
      }
    }
    @media (min-width: 768px) {
      margin-bottom: 75px;
    }
  }
}
