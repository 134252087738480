@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

body.single-luxury-buildings {
  background: $palegray!important;
}

.luxuryBuildingsIntro {
  margin-top: 59px;
  padding: 50px 0 100px 0;
  background: $palegray;
  background-size: cover;
  position: relative;
  h1, h2 {
    text-align: left;
  }
  h1 {
    margin: 0px;
  }
  h2 {
    color: $ltgray;
    text-transform: none;
    @include gothambook;
    letter-spacing: 0em;
    margin-bottom: 25px;
    font-size: (20rem/16);
  }
  @media (min-width: 768px) {
    padding: 100px 0 200px 0;
    h2 {
      font-size: (22rem/16);
      line-height: (30rem/16);
      margin-bottom: 45px;
    }
  }
  @media (min-width: 1120px) {
    margin-top: 74px;
  }
}

.page-template-luxury-buildings main, .luxury-buildings-template-default main {
  position: relative;
  z-index: 100;
  background: transparent;
  padding-bottom: 0px;
}

.luxuryBuildings, .singleLuxuryBuildings {
  background: $white;
  z-index: 100;
  position: relative;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  padding-top: 65px;
  @media (min-width: 450px) {
    width: calc(100% - 50px);
    margin: 0 auto 0 auto;
    margin-top: -65px;
  }
  @media (min-width: 768px) {
    padding-top: 100px;
    margin-top: -100px;
  }
  @media (min-width: 1200px) {
    width: 1175px;
    margin-top: -100px;
  }
  @media (min-width: 1300px) {
    width: 1275px;
  }
  @media (min-width: 1400px) {
    width: 1310px;
  }
}

.singleLuxuryBuildings {
  margin-top: 0px;
  padding-top: 0px;
  .slick-dots {
    display: none!important;
  }
  .singleLuxuryBuildings__navSlider {
    .slick-prev, .slick-next {
      display: none!important;
    }
  }
  a[href^="tel"] {
    color: $white !important;
  }
  ul  {
    li {
      color: $ltgray;
    }
  }
}

.luxuryBuildingsBlocks {
  > .col-md-6 {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 25px;
    img {
      width: 100%;
    }
    .image-price-wrap {
      position: relative;
      margin-bottom: 50px;
      .price {
        @include gothambold;
        font-size: (13rem / 16);
        color: $white;
        background: $palegray;
        padding: 12px 20px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%,50%);
        letter-spacing: 0.150em;
      }
      .img-wrap {
        width: 100%;
        height: 225px;
        position: relative;
        display: block;
        clear: both;
        overflow: hidden;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          min-width: 100%;
          min-height: 100%;
          display: block;
        }
      }
    }
    h4, p {
      text-align: center;
    }
    h4 {
      text-transform: uppercase;
      font-size: (15rem / 16);
      color: $textdkblue;
      letter-spacing: 0.100em;
      margin-bottom: 5px;
    }
    p {
      color: $textgrey;
      font-size: (14rem / 16);
      letter-spacing: 0.025em;
    }
    .propertyDetails {
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      font-size: (12rem / 16);
      letter-spacing: 0.025em;
      color: $textgrey;
      span {
        flex: 1;
        position: relative;
        &:after {
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 300%;
          content: "";
          background: #e5e5e5;
        }
        &:last-child:after {
          display: none;
        }
        a {
          @include gothambold;
          color: $textdkblue;
          text-transform: uppercase;
          &:after {
            content: "";
            background: url(../svg/right-arrow-gold.svg);
            position: absolute;
            top: 50%;
            right: calc(50% - 5px);
            transform: translate(30px, -50%);
            width: 10px;
            height: 10px;
            display: block;
            transition: all 0.15s ease-in-out;
          }
          &:hover {
            color: $dkgold;
            text-decoration: none;
            &:after {
              transform: translate(33px, -50%);
            }
          }
        }
        &:first-child {
          flex: 2;
        }
      }
    }
    @media (min-width: 768px) {
      margin-bottom: 75px;
    }
  }
}

.singleLuxuryBuildingsIntro {
  margin-top: 59px;
  padding: 50px 0 0px 0;
  background: $palegray;
  background-size: cover;
  position: relative;
  h1, h2 {
    text-align: left;
  }
  h1 {
    margin: 0px;
    margin-bottom: 4px;
    font-size: (32rem / 16);
  }
  h2 {
    color: $headerltgrey;
    text-transform: none;
    @include gothambook;
    letter-spacing: 0em;
    margin-bottom: 25px;
  }
  @media (min-width: 768px) {
    margin-top: 59px;
    padding: 100px 0 0px 0;
    h2 {
      font-size: (22rem/16);
      line-height: (30rem/16);
      margin-bottom: 45px;
    }
  }
  @media (min-width: 1120px) {
    margin-top: 74px;
  }
  &__buttons {
    width: 100%;
    display: block;
    border-top: 2px solid $bordergray;
    display: flex;
    justify-content: center;
    align-items: center;
    a.buttonLink {
      width: 50%;
      border: none;
      @include idlewild;
      color: $palegold;
      text-align: center;
      font-size: (12rem/16);
      padding: 25px 0 25px 0;
      br {
        @media (min-width: 768px) {
          display: none;
        }
      }
      &:first-child {
        border-right: 1px solid $bordergray;
      }
      &:last-child {
        border-left: 1px solid $bordergray;
      }
      &:after {
        display: none;
      }
      span.text {
        display: block;
        margin: 0 auto;
        position: relative;
        display: inline-block;
        &:after {
          transition: all 0.15s ease-in-out;
          content: "";
          position: absolute;
          top: 50%;
          right: -30px;
          display: block;
          transform: translateY(-50%);
          width: 15px;
          height: 15px;
          background: url(../svg/right-arrow-blue.svg);
          background-size: 15px 15px;
        }
      }
      @media (min-width: 768px) {
        &:hover {
          color: $white;
          background: transparent;
          span.text {
            &:after {
              opacity: 1;
              transform: translate(3px, -50%);
            }
          }
        }
      }
    }
  }
}

.singleLuxuryBuildings {
  @media (min-width: 450px) {
    width: 100%;
    margin: 0 auto 0 auto;
  }
  &__mainSlider {
    padding: 0px;
    margin: 0px;
    li {
      width:100%;
      img {
        width: 100%;
      }
    }
    @media (max-width: 449px) {
      margin: 0 auto 0 auto;
      margin-top: -125px;
    }
  }
  &__navSlider {
    padding: 0px;
    margin: 0px;
    li {
      height: 110px;
      img {
        width: 100%;
      }
    }
    @media (min-width: 768px) {
      margin-bottom: 100px;
    }
  }
  &__propertyOverview {
    h2 {
      margin-bottom: 25px;
    }
    h3 {
      color: $dkgold;
    }
    h4 {
      font-size: (22rem / 16);
      @include gothambook;
      color: $palegold;
      margin-bottom: 10px;
    }
    p:first-of-type {
      color: $white;
      @include gothambook;
      font-size: (22rem/16);
      line-height: (30rem/16);
    }
    .singleLuxuryBuildings__unitPricing {
      display: block;
      clear: both;
      margin-top: 20px;
      margin-bottom: 20px;
      .row {
        border-top: 1px solid $dividerltgrey;
        padding: 19px 0 21px 0;
        &:last-child {
          border-bottom: 1px solid $dividerltgrey;
        }
      }
      h5, p {
        text-transform: uppercase;
        @include gothambold;
        font-size: (15rem / 16);
        letter-spacing: 0.150em;
        color: $ltgray;
        margin-bottom: 0px;
        line-height: 1em;
      }
      h5 {
        color: $palegold;
      }
      @media (min-width: 768px) {
        margin-top: 45px;
        margin-bottom: 45px;
      }
    }
    .singleLuxuryBuildings__detailsFeatures {

    }
  }
  .singleLuxuryBuildings__sideBar {
    .buttonLink {
      width: 100%;
      &:first-of-type {
        margin-bottom: 5px;
      }
      &:nth-of-type(2) {
        margin-bottom: 50px;
      }
    }
    // .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium, .gform_wrapper input, .gform_wrapper textarea {
    //   background: $spacegrey;
    // }
    // .gform_wrapper input[type=submit] {
    //   background: $dkblue;
    //   &:hover {
    //     background: $dkgold;
    //   }
    // }
  }
}

section.singleLuxuryBuildings__similarWrap {
  background: $white;
  width: calc(100% - 30px);
  display: block;
  clear: both;
  background: $white;
  padding-top: 75px;
  padding-bottom: 75px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  position: relative;
  z-index: 100;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    width: calc(100% - 130px);
    margin-top: 100px;
  }
}
