@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";

.home main {
  padding-bottom: 0px;
}

.home {
  @media (max-width: 767px) {
    .liliBioContactWrap {
        margin-top: -225px!important;
    }
  }
}

.homeVideo {
  width: 100vw;
  height: calc(100vh);
  object-fit: cover;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: -1;
  @media (min-width: 1120px) {
    height: calc(100vh);
    top: 0px;
  }
}

.homeVideoGradient {
  position: absolute;
  width: 100vw;
  height: calc(100vh);
  position: fixed;
  z-index: 2;
  top: 0px;
  left: 0px;
  background: linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.3) 100%);
}

.homeIntro {
  background: transparent;
  background-size: cover;
  height: calc(100vh);
  position: relative;
  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  &__searchWrapper {
    width: 100%;
    height: 100%;
    display: block;
    background: rgba(46,47,51,0.10);
  }
  &__search {
    padding: 45px;
    background: rgba(46,47,51,0.90);
    position: absolute;
    bottom: 100px;
    right: 50px;
    z-index: 1;
    @media (max-width: 767px) {
      position: absolute;
      right: 0px;
      bottom: 0px;
      width: 100%;
    }
  }
  h1, h2 {
    text-align: left;
  }
  h1 {
    margin: 0px;
    white-space: nowrap;
    font-size: (42rem/16);
  }
  h2 {
    color: #d5d5d5;
    text-transform: none;
    @include gothambook;
    letter-spacing: -0.010em;
    margin-bottom: 25px;
  }
  @media (min-width: 1120px) {
    height: calc(100vh);
    h2 {
      font-size: (22rem/16);
      line-height: (30rem/16);
      margin-bottom: 45px;
    }
  }
}

.homeluxuryBuildingsIntro {
  margin-top: 0px !important;
  z-index: 100;
  h2.h1-style {
    @include gothambook;
    color: $white !important;
    text-transform: uppercase !important;
    font-size: 1.5rem !important;
    letter-spacing: 0.100em !important;
    @media (min-width: 768px) {
      font-size: 2.625rem!important;
      margin-bottom: 50px!important;
    }
  }
  @media (min-width: 768px) {
    padding-bottom: 100px!important;
  }
}

.homeExListings {
  background: $palegray;
  width: 100%;
  display: block;
  clear: both;
  padding-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 100;
  @media (min-width: 768px) {
    padding-top: 55px;
  }
  h2 {
    text-align: left;
    color: $white;
    margin-bottom: 40px;
  }
  @media (min-width: 768px) {
    width: 100%;
  }
}

.homeExListingsSlider {
  .slide {
    h4, p, span {
      color: $white;
    }
    h4 {
      @include gothambook;
      font-size: (16rem/16);
      line-height: (26rem/16);
      text-align: left;
      @media(min-width: 1100px) {
        font-size: (26rem/16);
        line-height: (36rem/16);
      }
    }
    p {
      @include gothambook;
      margin-bottom: 29px;
      font-size: (14rem/16);
      @media (min-width: 1100px) {
        font-size: (16rem/16);
        text-align: left;
        color: $ltgray;
      }
    }
    .propertyDetails {
      border-bottom: none;
      border-top: 2px solid rgba(255,255,255,0.1);
      span {
        &:after {
          height: 100%;
          width: 2px;
          background: rgba(255,255,255,0.1);
        }
      }
    }
    .image-price-wrap {
      margin-bottom: 0px;
    }
  }
  &__propertDetailsWrap {
    @include gothambook;
    position: absolute;
    right: 150px;
    bottom: 0px;
    display: block;
    background: rgba(46,47,51,0.95);
    padding: 30px;
    @media (max-width: 768px) {
      padding: 8px;
      right: 7px;
      bottom: 0px;
      .propertyDetails {
        padding-top: 10px!important;
        padding-bottom: 10px!important;
      }
      h4 {
        text-align: center!important;
        font-size: (12rem/16);
        line-height: (18rem/16);
      }
      p {
        font-size: (10rem/16)!important;
        margin-bottom: 10px!important;
        line-height: 0.8rem;
      }
      a.buttonLink {
        padding-top: 8px!important;
        padding-bottom: 8px!important;
      }
    }
    @media (min-width: 768px) {
      padding: 45px;
    }
    @media (min-width: 992px) {
      padding: 65px;
    }
  }
}

.homeNeighborhoods {
  background: $tan;
  padding: 45px 0 75px 0;
  width: 100%;
  display: block;
  clear: both;
  z-index: 100;
  position: relative;
  ul {
    padding: 0px;
    margin: 0px;
    list-style: none;
    color: $dkgold;
    text-transform: uppercase;
    font-size: (13rem/16);
    @include gothambold;
    letter-spacing: 0.150em;
    li {
      padding: 20px 0;
      border-bottom: 1px solid $bordergray;
      width: 100%;
      a {
        color: $dkgold;
        transition: 0.15s all ease-in-out;
        &:hover {
          color: $white;
        }
      }
    }
    @media (min-width: 768px) {
      max-width: calc(100% - 14px);
      margin: 0 auto;
    }
  }
  @media (min-width: 768px) {
    padding: 150px 0 150px 0;
  }
  @media (min-width: 992px) {
    padding-bottom: 225px;
  }
}

.homeBlog {
  padding: 45px 0;
  margin: 0px;
  list-style: none;
  width: 100%;
  background: $palegraylt;
  z-index: 100;
  position: relative;
  h2 {
    color: $white;
  }
  article {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid $spacegrey;
    a {
      img {
        max-width: 100%;
        margin-bottom: 5px;
      }
      h1 {
        color: $white;
        @include gothambook;
        text-transform: none;
        letter-spacing: 0.150em;
        font-size: (15rem/16);
        transition: color 0.15s ease-in-out;
      }
      span {
        color: $textgrey;
        transition: color 0.15s ease-in-out;
      }
      &:hover {
        h1, span {
          color: $dkgold;
        }
      }
    }
  }
  @media (min-width: 768px) {
    padding: 135px 0;
  }
}

.homeLuxuryBuildings {
  background: $palegray;
  z-index: 100;
  position: relative;
  h2 {
    @include idlewild;
    color: $palegold;
    font-size: (14rem/16);
  }
  h3 {
    color: $white;
    @include gothambook;
  }
  p {
    color: $ltgray;
  }
  .buttonLink {
    max-width: 225px;
  }
  .textSide {
    .textSideInside {
      @media (max-width: 767px) {
          padding: 30px 15px;
      }
    }
  }
}

.home .liliBio {
  z-index: 100;
  position: relative;
}

.home .liliBio .liliBioContactWrap {
  z-index: 100;
  position: relative;
  @media (min-width: 992px) {
    margin-top: -225px;
  }
}
