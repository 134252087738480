@import "../globals/variables";
@import "../globals/mixins";
@import "../globals/colors";
@import "../globals/text-fonts";


body {
  background: $spacegrey;
}

main {
  z-index: 1;
  width: 100%;
  display: block;
  position: relative;
  padding-bottom: 150px;
  background: $spacegrey;
}

.imageTextBandWrap {
  // -webkit-box-shadow: 0 0 50px 0 rgba(0,0,0,0.10);
  // box-shadow: 0 0 50px 0 rgba(0,0,0,0.10);
  background: $spacegrey;
  position: relative;
  h2 {
    color: $black;
    text-align: left;
    margin-bottom: 16px;
  }
  h3 {
    color: $dkgold;
    &.divider:after {
      left: 0px;
      transform: none;
    }
  }
  p {
    color: $textgrey;
  }
  .imageSide {
    width: 100%;
    display: block;
    box-sizing: border-box;
    clear: both;
    float: left;
    .imageSideInside {
      display: block;
      clear: both;
      width: 100%;
      position: relative;
    }
    img {
      min-width: 100%;
      min-height: 100%;
    }
  }
  .textSide {
    width: 100%;
    display: block;
    // background: $spacegrey;
    box-sizing: border-box;
    clear: both;
    .textSideInside {
      display: block;
      clear: both;
      padding: 15px;
      width: 100%;
      box-sizing: border-box;
    }
  }
  &:first-of-type {
    margin-top: 200px;
    @media (min-width: 992px) {
      margin-top: -175px;
    }
  }
  @media (max-width: 991px) {
    .imageSide {
      position: absolute;
      bottom: calc(100%);
      width: calc(100% - 30px);
      left: 15px;
      height: 250px;
      overflow: hidden;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
  }
  @media (min-width: 992px) {
    display: flex;
    overflow: hidden;
    .imageSide {
      width: 50%;
      float: left;
      clear: none;
      flex: 1;
      overflow: hidden;
      display: flex;
      .imageSideInside {
        flex: 1;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
      }
    }
    .textSide {
      padding: 195px 110px 150px 110px;
      width: 50%;
      float: right;
      clear: none;
      flex: 1;
    }
  }
  &.alt {
    @media (min-width: 992px) {
      .textSideInside {
        float: right;
      }
    }
  }
}

.slick-slider {
  .slick-slide {
    margin-bottom: 0px;
  }
  .slick-prev, .slick-next {
    display: block;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50%);
    transform: translateY(-50%);
    z-index: 100;
    border: 0px;
    background: $white;
    overflow: hidden;
    color: transparent;
    transition: all 0.15s ease-in-out;
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      content: url(../svg/left-arrow-black.svg);
    }
    &:hover, &:focus {
      outline: none;
    }
    @media (min-width: 768px) {

    }
    @media (min-width: 768px) {
      width: 75px;
      height: 75px;
      &:hover {
        background: $dkgold;
      }
      &:after {
        width: 15px;
        height: 15px;
      }
    }
    @media (min-width: 992px) {
      width: 100px;
      height: 100px;
      &:hover {
        background: $dkgold;
      }
      &:after {
        width: 34px;
        height: 34px;
      }
    }
  }
  .slick-prev {
    left: 0px;
    &:after {
      @media (max-width: 767px) {
        transform: translate(-50%, -11px);
      }
    }
  }
  .slick-next {
    right: 0px;
    transform: translateY(-50%);
    &:after {
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: url(../svg/right-arrow-black.svg);
      @media (max-width: 767px) {
        transform: translate(-50%, -11px);
      }
    }
  }
  .slick-dots {
    display: block;
    float: right;
    clear: both;
    list-style: none;
    li {
      display: inline-block;
      button {
        color: transparent;
        background: $paragraphgray;
        border-radius: 12px;
        display: block;
        border: none;
        width: 12px;
        height: 12px;
        margin-left: 5px;
        overflow: hidden;
        transition: all 0.15s ease-in-out;
      }
      &.slick-active {
        button {
          background: $ltgray;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .slick-prev {
      top: 50%;
    }
    .slick-next {
      top: 50%;
    }
  }
  @media (min-width: 576px) {
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
  }
}

.liliBio {
  padding-top: 150px;
  padding-bottom: 75px;
  background: $white;
  h4 {
    color: $textgrey;
    margin-bottom: 40px;
  }
  .liliBioContactWrap {
    background: $palegray;
    border-radius: 0;
    .gform_wrapper label.gfield_label {
      display: none;
    }
    .liliBioContactTextWrap {
      padding: 20px;
      h3, p {
        color: $ltgray;
      }
      h3 {
        color: $white;
        @include gothambook;
        margin-bottom: 5px;
        letter-spacing: 0.050em;
      }
      p {
        font-size: (14rem / 16);
        letter-spacing: 0.025em;
      }
      @media (min-width: 768px) {
        padding: 48px;
      }
    }
    @media (max-width: 640px) {
      padding-bottom: 25px;
    }
    @media (min-width: 641px) and (max-width: 767px) {
      padding-bottom: 50px;
    }
    @media (max-width: 767px) {
      margin-top: -140px;
      margin-bottom: 55px;
    }
  }
  @media (min-width: 768px) {

    padding-bottom: 150px;
    h2 {
      margin-bottom: 5px;
    }
  }
}

ul.SidebarActionList {
  list-style: none;
  display: block;
  clear: both;
  width: 100%;
  padding: 0;
  margin: 0 0 35px 0;
  li {
    width: 100%;
    border-top: 1px solid $dividerltgrey;
    margin-bottom: 0px;
    &:last-of-type {
      border-bottom: 1px solid $dividerltgrey;
    }
    a {
      @include gothambold;
      font-size: (13rem / 16);
      color: $dkblue;
      color: $dkblue;
      line-height: 1em;
      padding: 18px 0 18px 30px;
      width: 100%;
      display: block;
      letter-spacing: 0.150em;
      text-transform: uppercase;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        width: 20px;
        height: 20px;
      }
      @media (min-width: 768px) {
        &:hover {
          color: $dkgold;
        }
      }
    }
    &:nth-child(1) a:before {
      background: url(../svg/print-icon-gold.svg) center center no-repeat;
      background-size: cover;
    }
    &:nth-child(2) a:before {
      background: url(../svg/email-icon-gold.svg) center center no-repeat;
      background-size: cover;
    }
    &:nth-child(3) a:before {
      background: url(../svg/phone-icon-gold.svg) center center no-repeat;
      background-size: cover;
    }
    &:nth-child(4) a:before {
      background: url(../svg/share-icon-gold.svg) center center no-repeat;
      background-size: cover;
    }
    @media (min-width: 768px) {
      &:nth-child(3) {
        display: none;
      }
    }
  }
  @media (min-width: 768px) {
    margin: 0 0 50px 0;
  }
}

.homeExListingsSlider, .singleLuxuryBuildings__similarSlider, .singleTopListings__similarSlider {
  width: 100%;
  .slide {
    width: 33.33333333%;
    padding: 0 7px;
    box-sizing: border-box;
    position: relative;
    .img-wrap {
      height: 50.14492754vw;
      overflow: hidden;
      position: relative;
    }
    img {
      min-width: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .image-price-wrap {
      position: relative;
      margin-bottom: 50px;
      display: block;
      .price {
        @include gothambold;
        font-size: (13rem / 16);
        color: $white;
        background: $palegray;
        padding: 12px 20px;
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%,50%);
        letter-spacing: 0.150em;
      }
    }
    h4, p {
      text-align: center;
    }
    h4 {
      text-transform: uppercase;
      font-size: (15rem / 16);
      color: $textdkblue;
      letter-spacing: 0.100em;
      margin-bottom: 5px;
    }
    p {
      color: $textgrey;
      font-size: (14rem / 16);
      letter-spacing: 0.025em;
    }
    .propertyDetails {
      text-align: center;
      border-bottom: 1px solid #e5e5e5;
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      font-size: (12rem / 16);
      letter-spacing: 0.025em;
      color: $textgrey;
      span {
        flex: 1;
        position: relative;
        &:after {
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 300%;
          content: "";
          background: #e5e5e5;
        }
        &:last-child:after {
          display: none;
        }
        a {
          @include gothambold;
          color: $textdkblue;
          text-transform: uppercase;
          display: block;
          position: relative;
          &:after {
            content: "";
            background: url(../svg/right-arrow-gold.svg);
            position: absolute;
            top: 50%;
            right: calc(50% - 5px);
            transform: translate(30px, -50%);
            width: 10px;
            height: 10px;
            display: block;
            transition: all 0.15s ease-in-out;
          }
          &:hover {
            color: $dkgold;
            text-decoration: none;
            &:after {
              transform: translate(33px, -50%);
            }
          }
        }
      }
    }
  }
}

.singleLuxuryBuildings__similarSlider, .singleTopListings__similarSlider {
  .slide {
    .img-wrap {
      height: 225px;
    }
  }
}

a.image-price-wrap {
  display: block;
  .price {
    transition: all 0.15s ease-in-out;
  }
  @media (min-width: 768px) {
    &:hover {
      .price {
        color: $palegray!important;
        background: $palegold!important;
      }
    }
  }
}

.pagination {
  font-size: (13rem / 16);
  @include gothambold;
  text-transform: uppercase;
  span, a {
    display: inline-block;
    padding: 0px 15px;
  }
  span {
    color: $dkgold;
  }
  a {
    color: $dkblue;
    @media (min-width: 768px) {
      &:hover {
        color: $dkgold;
      }
    }
  }
}

#google-map {
  width: 100%;
  height: 400px;
  display: block;
  clear: both;
  margin-bottom: 75px;
  margin-top: 25px;
  h4 {
    font-size: (16rem /16);
    margin: 0;
  }
  p {
    color: $textgrey;
    font-size: (14rem / 16);
    line-height: 1.4em;
    margin-bottom: 0px;
  }
}

.col-md-6 .image-price-wrap .price {
  text-align: center!important;
}

ul.checks {
  margin-top: 35px;
  padding: 0px;
  li {
    list-style: none;
    font-size: (14rem/16);
    @include gothamitalic;
    color: $palegray;
    position: relative;
    margin: 0px;
    padding: 15px 0px 13px 25px;
    border-bottom: 2px solid #eaeaea;
    &:first-child {
      border-top: 2px solid #eaeaea;
    }
    &:before {
      content: "";
      width: 18px;
      height: 18px;
      display: block;
      position: absolute;
      top: 17px;
      left: 0px;
      background: url("../svg/check-burst.svg") center center;
      background-size: cover;
    }
  }
}

.dark ul.checks {
  li {
    color: $ltgray;
    &:before {
      background: url("../svg/check-burst-light.svg") center center;
    }
  }
}

.liliBio {
  ul.checks {
    li {
      font-size: (20rem/16)!important;
      padding: 18px 0px 16px 35px!important;
    }
    &:before {
      width: 24px!important;
      height: 24px!important;
      top: 21px!important;
    }
  }
}

section.dark {
  background: $palegray;
  h2 {
    font-size: (14rem/16);
    @include idlewild;
    color: $palegold;
  }
  h2.h1-style {
    color: $white;
  }
  h3 {
    color: $white;
  }
  h4 {
    color: $ltgray;
  }
  p {
    color: $ltgray;
  }
  .pagination {
    span.current {
      color: $white;
    }
    a {
      color: $ltgray;
      @media (min-width: 768px) {
        &:hover {
          color: $palegold;
        }
      }
    }
  }
  &__header {
    h2 {
      @include gothambook;
      &.h1-style {
        font-size: (24rem/16);
        letter-spacing: 0.100em;
        @media (min-width: 768px) {
          font-size: (42rem/16);
        }
      }
    }
  }
  .singleLuxuryBuildings__sideBar, .singleTopListings__sideBar {
    a.buttonLink {
      border-color: rgba(255,255,255,0.3);
      color: $ltgray;
      margin-bottom: 15px;
      @media (min-width: 768px) {
        &:hover {
          border-color: rgba(255,255,255,1);
          background: transparent;
          color: $white;
        }
      }
      &:first-child {
        background: $white;
        color: $palegray;
        &:after {
          background: url("../svg/right-arrow-gray.svg");
        }
        @media (min-width: 768px) {
          &:hover {
            background: $dkgold;
            border-color: $dkgold;
          }
        }
      }
    }
    #gform_wrapper_5 {
      margin-bottom: 50px;
    }
  }
  .SidebarActionList {
    li {
      border-top: 2px solid rgba(255, 255, 255, 0.3);
      &:last-child {
        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      }
    }
    a {
      color: $ltgray;
      @media (min-width: 768px) {
        &:hover {
          color: $white;
        }
      }
    }
  }
}

section.light {
  h1.h2-style {
    color: $palegray;
  }
  h2 {
    font-size: (14rem/16);
    @include idlewild;
    color: $palegray;
  }
  p {
    color: $paragraphgray;
  }
  #gform_wrapper_5 {
    background: transparent;
    margin-top: 0px;
    padding: 0px;
    label {
      color: $palegray;
    }
  }
}
